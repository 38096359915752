<template>
  <svg class="icon-update" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_181_17215" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask0_181_17215)">
      <path d="M18.5531 12.9572C18.5531 16.5474 15.6322 19.4678 12.0426 19.4678C8.45213 19.4678 5.53198 16.5471 5.53198 12.9572C5.53198 9.36709 8.45213 6.44667 12.0426 6.44667V8.36157L14.7233 5.68078L12.0426 3V4.91489C7.60766 4.91489 4 8.52296 4 12.9574C4 17.3919 7.6078 21 12.0426 21C16.4772 21 20.0851 17.3919 20.0851 12.9574L18.5531 12.9572Z" fill="black"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconUpdate"
}
</script>

<style lang="scss" scoped>
.icon-update {
  cursor: pointer;
}
</style>